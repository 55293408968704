import _ from 'lodash';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import './ConfirmationPage.scss';
import NateraImg from './natera_desktop.svg';
import NateraMobImg from './natera_mobile.svg';
import gmiLogo from '../../../../images/minimized_logo.png';


const ConfirmationPage = (props) => {

    const {
        isMobile
    } = props;

    return (
        <div>
            <div className="scp-mob-schedule-breadcrumb">
                <div className={classnames('m-breadcrumb-content', 'm-scheduling-breadcrumb-content')}>
                    <Fragment>
                        <div className="logo">
                            <img src={gmiLogo} className="header-logo" />
                        </div>
                    </Fragment>
                </div>
            </div>
            <div className={classnames('test-request-wizard test-confirm-form')}>
                <h2 className="gm-select-service__header">{'Your test has been requested!'}</h2>
                <div className='natera-cont'>
                    <div className='natera-detail'>
                        <div className='img-section'>
                            <img src={isMobile ? NateraMobImg : NateraImg} />
                        </div>
                        <div className='expect-next'>
                            <div className='expect-heading'>Here’s what to expect next: </div>
                            <div className='expect-desc'>
                                <ul>
                                    <li>
                                        You should receive a saliva kit in the mail from Natera within five days. </li>
                                    <li> Please follow the instructions in the kit and send it back to Natera within five days of sample collection. </li>
                                    <li>You will receive an email from Genome Medical with a link to create your account and track your order.</li>
                                    <li>Your test results will be available in the Genome Medical portal in about two weeks. </li>
                                    <li>You will receive an email when your results are ready. </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='still-have-question'>
                        <div className='still-label'> Still have questions? </div>
                        <div className='reach-out'>Reach out to <a key="mail-to" href="mailto: support@genomemedical.com">
                            support@genomemedical.com
                        </a> for assistance. </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ConfirmationPage;
